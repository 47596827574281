// External Dependencies
import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'gatsby';

// Internal Dependencies
import { banner } from '../utils/constants/styles';

// Component Definition
const Banner = () => {
  const linkToGoogleForm = process.env.GATSBY_APP_LINK_TO_RAFFLE_SIGN_UP_FORM;

  return (
    <Box
      className="banner center"
      sx={banner}
    >
      <p>
        Enter to win a year of Presto!
        {' '}
        <Box margin={1}>
          <Link
            className="inline-button"
            href={linkToGoogleForm}
          >
            Get started
          </Link>
        </Box>
      </p>
    </Box>
  );
};

export default Banner;
