export const form = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  maxWidth: 600,
};

export const formButton = {
  margin: 10,
};

export const formWrapper = {
  alignSelf: 'stretch',
  display: 'flex',
  flexGrow: 1,
  flexWrap: 'wrap',
  '& > div': {
    flexGrow: 1,
    margin: 15,
  },
};

export const landingPageBgStyle = {
  backgroundSize: 'cover',
  height: '70vh',
  position: 'relative',
};

export const pageLayout = {
  paddingBottom: 30,
  paddingTop: 30,
};

export const relative = {
  position: 'relative',
};

export const banner = {
  height: 85,
};

export const promotionBanner = {
  height: 108,
};
