// External Dependencies
import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'gatsby';

// Internal Dependencies
import { promotionBanner } from '../utils/constants/styles';

// Component Definition
const PromotionBanner = () => {
  return (
    <Box
      className="promotion-banner center"
      sx={promotionBanner}
    >
      <p>
        Switch to Presto, get 6 months for free!
      </p>
      <Box margin={1}>
        <Link
          className="promotion-inline-button"
          href="/switch"
        >
          Learn More
        </Link>
      </Box>
    </Box>
  );
};

export default PromotionBanner;
